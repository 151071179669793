import i18n from "i18next";
import {
    initReactI18next
} from "react-i18next";
import enTranslations from "./assets/translations/en.json";
import itTranslations from "./assets/translations/it.json";
import esTranslations from "./assets/translations/es.json";
import frTranslations from "./assets/translations/fr.json";

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: enTranslations,
        },
        it: {
            translation: itTranslations,
        },
        es: {
            translation: esTranslations,
        },
        fr: {
            translation: frTranslations,
        },
    },
    debug: true,
    lng: "en",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
});
export default i18n;