/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import Animation from "../../assets/images/Animation.gif";
import img8 from "../../assets/images/img-8.png";
import img2 from "../../assets/images/img-2.png";
import img3 from "../../assets/images/img-3.png";
import img4 from "../../assets/images/img-4.png";
import { Link } from "react-router-dom";
import { common } from "./../../constant/commonData";
import { useTranslation } from "react-i18next";
import { isAuthenticated } from "../../helper/Authorization";

const Footer = () => {
  const { t } = useTranslation();
  const socialLink = common.socialLink
  return (
    <footer>
      <div className="belford-footer-top">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8">
              <div className="belford-footer-top-content">
                <h2>{t("footerData.topContent.H2Text")}</h2>
                <p>{t("footerData.topContent.PText1")}</p>
                <p>{t("footerData.topContent.PText2")}</p>
                <p>{t("footerData.topContent.PText3")}</p>
                <Link to="/register" className="belford-btn">
                  {t("footerData.topContent.belfordBtn.Text")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="belford-footer-middle">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-9">
              <div className="belford-footer-middle-box">
                <div className="row align-items-center">
                  <div className="col-12 col-lg-6">
                    <div className="belford-footer-middle-img">
                      <img src={Animation} className="img-fluid" />
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="belford-footer-middle-content">

                      <h2>{t("footerData.footerMiddle.H2Text")}</h2>
                      <p>{t("footerData.footerMiddle.PText")}</p>
                      <Link to="/register" className="belford-btn me-2">
                        {t("footerData.footerMiddle.belfordBtn.Text")}
                      </Link>
                      {!isAuthenticated() && <Link to="/startnow" className="belford-btn">
                        Check Your Level
                      </Link>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="belford-footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-3">
              <div className="belford-footer-bottom-img">
                <div className="belford-footer-bottom-img">
                  <a href="#">
                    <img src={img8} />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="belford-footer-bottom-content">
                <p>{t("footerData.footerBottom.PText1")}</p>
                <ul>
                  <li>
                    <Link to="/privacy-policy">{t("footerData.footerBottom.PText2")}</Link>
                  </li>
                  <li>
                    <Link to="/terms-and-condition">{t("footerData.footerBottom.PText3")}</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="belford-footer-bottom-social-icon">
                <ul>
                  <li>
                    <a href={socialLink.facebook}>
                      <img src={img2} />
                    </a>
                  </li>
                  <li>
                    <a href={socialLink.instagram}>
                      <img src={img3} />
                    </a>
                  </li>
                  <li>
                    <a href={socialLink.linkedin}>
                      <img src={img4} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="belford-footer-copy-right">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="belford-footer-site-info text-right">
                <p>
                  {t("footerData.copyRight.PText.Text")}
                  <a href="#">{t("footerData.copyRight.PText.anchorText")}</a>
                  {t("footerData.copyRight.PText.textAfter")} - Made by <a href="https://webviewdigital.com">Webview Digital</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
