/* eslint-disable react/style-prop-object */
import React, {useState, useEffect} from 'react'
import Body from './common/Body'
import imgshop3 from "../assets/images/img-shop-3.png"
import imgshop4 from "../assets/images/autumn-in-a-magic-forest.png"
import { useTranslation } from 'react-i18next'
const Shop = () => {
    const { t, i18n } = useTranslation();
    const [link, setLink] = useState('https://www.amazon.it/English-Horizon-Corso-Lingua-Inglese/dp/B0CJB38F6Q/ref=sr_1_1?crid=36RGCFG6H0S5K&dib=eyJ2IjoiMSJ9.RWniJEvbmpcUHduurhz7XrE84pmON0d3OOPmDLJfvFLRe5DlkIoNBTec62UJRj3_gKHwoHWQ4um5SNM4Vlcej6iDq8uTTqnodd94D0dD56y-hsM8xgcpWmhgu13rxGC7YilroWvwjlNmxtVoRo5Q7OELba18j98xKXCO1wrP9wRsZReOCXsWpBkSdpKD5Nhh7ghcrI-PvHK5kkHoJXSXCPNXCLwT6fCabwrz8aJjeTYPqY4M9K1CictPgh-o2YjWp-VMusX1RcKoPI4I7gPVD-7KTSFYXkeWeL9B9-7FdPE.0ye9vq_YyKI1g-BBpdH4JO7Vi-6jQ6W2Th_qM_lVwqw&dib_tag=se&keywords=english+horizon&qid=1713375999&sprefix=english+horizon%2Caps%2C130&sr=8-1')

    useEffect(()=>{
        console.log(i18n.language);
        if(i18n.language === 'it'){
            setLink('https://www.amazon.it/dp/B0D3F8BTD1');
        } else if(i18n.language === 'es'){
            setLink('https://www.amazon.es/dp/B0D3F8BTD1');
        } else {
            setLink('https://www.amazon.it/English-Horizon-Corso-Lingua-Inglese/dp/B0CJB38F6Q/ref=sr_1_1?crid=36RGCFG6H0S5K&dib=eyJ2IjoiMSJ9.RWniJEvbmpcUHduurhz7XrE84pmON0d3OOPmDLJfvFLRe5DlkIoNBTec62UJRj3_gKHwoHWQ4um5SNM4Vlcej6iDq8uTTqnodd94D0dD56y-hsM8xgcpWmhgu13rxGC7YilroWvwjlNmxtVoRo5Q7OELba18j98xKXCO1wrP9wRsZReOCXsWpBkSdpKD5Nhh7ghcrI-PvHK5kkHoJXSXCPNXCLwT6fCabwrz8aJjeTYPqY4M9K1CictPgh-o2YjWp-VMusX1RcKoPI4I7gPVD-7KTSFYXkeWeL9B9-7FdPE.0ye9vq_YyKI1g-BBpdH4JO7Vi-6jQ6W2Th_qM_lVwqw&dib_tag=se&keywords=english+horizon&qid=1713375999&sprefix=english+horizon%2Caps%2C130&sr=8-1');
        }
    });

    return (
        <Body>
            <section className="belford-shop-hero-section" id="belford-shop-hero-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="belford-shop-hero-content text-center">
                                <h1 className="mb-0">{t("shopData.heroSection.H1Text")}</h1>
                                <h1><span>{t("shopData.heroSection.SpanText")}</span></h1>
                                <p>{t("shopData.heroSection.PText")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="belford-shop-section" id="belford-shop-section">
                <div className="container">
                    <div className="row gy-4 gy-lg-0">
                        <div className="col-12 col-md-6 col-lg-3">
                            <div className="belford-shop-box text-center">
                                <div className="belford-shop-box-inner">
                                    <div className="belford-shop-img">
                                        <img src={imgshop3} alt='imgshop3' />
                                    </div>
                                    <div className="belford-shop-content">
                                        <h4>{t("shopData.shopSection.Box1.shopcontent.H4Text")}</h4>
                                        <p>{t("shopData.shopSection.Box1.shopcontent.PText")}</p>
                                    </div>
                                </div>
                                <div className="belford-shop-btn">
                                    <a href="https://www.amazon.it/English-Horizon-Corso-Lingua-Inglese/dp/B0CJB38F6Q/ref=sr_1_1?crid=36RGCFG6H0S5K&dib=eyJ2IjoiMSJ9.RWniJEvbmpcUHduurhz7XrE84pmON0d3OOPmDLJfvFLRe5DlkIoNBTec62UJRj3_gKHwoHWQ4um5SNM4Vlcej6iDq8uTTqnodd94D0dD56y-hsM8xgcpWmhgu13rxGC7YilroWvwjlNmxtVoRo5Q7OELba18j98xKXCO1wrP9wRsZReOCXsWpBkSdpKD5Nhh7ghcrI-PvHK5kkHoJXSXCPNXCLwT6fCabwrz8aJjeTYPqY4M9K1CictPgh-o2YjWp-VMusX1RcKoPI4I7gPVD-7KTSFYXkeWeL9B9-7FdPE.0ye9vq_YyKI1g-BBpdH4JO7Vi-6jQ6W2Th_qM_lVwqw&dib_tag=se&keywords=english+horizon&qid=1713375999&sprefix=english+horizon%2Caps%2C130&sr=8-1" className="belford-btn">{t("shopData.shopSection.Box1.shopbtn.LinkText")}</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                            <div className="belford-shop-box text-center">
                                <div className="belford-shop-box-inner">
                                    <div className="belford-shop-img">
                                    <img src={imgshop4} alt="imgshop4s" style={{ height: '216px' }} />
                                    </div>
                                    <div className="belford-shop-content">
                                        <h4>{t("shopData.shopSection.Box2.shopcontent.H4Text")}</h4>
                                        <p>{t("shopData.shopSection.Box2.shopcontent.PText")}</p>
                                    </div>
                                </div>
                                <div className="belford-shop-btn">
                                    <a href={link} className="belford-btn">
                                        {t("shopData.shopSection.Box1.shopbtn.LinkText")}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Body>
    )
}

export default Shop