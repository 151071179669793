import React from "react";
import Footer from "./Footer";
import Header from "./Header";

const Body = ({ children }) => {
  return (
    <React.Fragment>
      <Header />
      {children}
      <Footer />
    </React.Fragment>
  );
};

export default Body;
