import React, { useEffect, useState } from "react";
import Body from "./common/Body";
import imgtranslation2 from "../assets/images/img-translation-2.png";
import PrivateMenu from "./common/PrivateMenu";
import { getCurrentUser } from "../helper/Authorization";
import { API } from "../helper/api";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
const api = new API();

const Welcome = () => {
  const [userProgess, setUserProgess] = useState(0)
  const [userLevel, setUserLevel] = useState(null);

  useEffect(() => {
    getProgress()
    const currentUser = getCurrentUser();
    if (currentUser.student) {
      setUserLevel(currentUser.student.level.name);
    }
  }, []);

  const getProgress = async () => {
    try {
      const response = await api.get("STUDENT_PROGRESS");
      if (response.status === true) {
        setUserProgess(response.data.progress)
      }
    } catch (error) {
      console.error("Error while get Progess Test", error);
      toast.error(error.data.message);
    }
  }

  return (
    <Body>
      <section
        className="belford-withdraw-hero-section belford-withdraw-golden"
        id="belford-withdraw-hero-section"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8">
              <div className="belford-withdraw-hero-content text-center">
                <PrivateMenu />
                <h1>
                  <span className="belford-title-yellow-color">
                    Welcome to The Platform
                  </span>
                </h1>
                <p>
                  Your Level <span>{userLevel}</span>
                </p>
                <div className="progress mb-3">
                  <div className="progress-bar" role="progressbar" style={{ width: `${userProgess}%` }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" ></div>
                </div>
                <p>
                  You have completed {userProgess}% of your English course.
                </p>
                <Link to="/units" className="belford-btn">
                  GO TO UNITS
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="belford-translation-about-section"
        id="belford-translation-about-section"
      >
        <div className="container">
          <div className="row g-4 align-items-center">
            <div className="col-lg-6">
              <div className="about-image-translation">
                <img
                  src={imgtranslation2}
                  className="img-fluid"
                  alt="about-img"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-title-translation">
                <h2>
                  <span>An immense range of languages,</span> an easy access to
                  a new type of translation{" "}
                </h2>
              </div>
              <div className="about-content">
                <p>
                  Books, novels, newspapers, magazines, to documents and formal
                  papers. There is no written form of communication we can’t
                  deal with. Belford Woods School can assist all customers along
                  the translation procedure, delivering in a very short time the
                  best possible product.
                </p>
                <p>
                  Everything clear – we evaluate the text – we send you a
                  quotation – we start working – you receive your translated
                  text.
                </p>
              </div>
              <div className="about-translation-btn">
                <a href="/">Confidentiality is the key</a>
              </div>
              <div className="about-content">
                <p>
                  We are proud to say that discretion is our main
                  characteristic. Nothing is ever released or published. All
                  information received is constantly protected and kept in a
                  safe place.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Body>
  );
};

export default Welcome;
