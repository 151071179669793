import React from "react";
import { privateRoute } from "./../../Routes/allRoutes";
import { NavLink } from "react-router-dom";
import { getCurrentUser } from "./../../helper/Authorization";
const currentUser = getCurrentUser();
const PrivateMenu = () => {
  return (
    <ul className="belford-tab-bar">
      {privateRoute.map((route, index) => {
        // Conditional check
        if (currentUser?.student?.course_id !== 1 && route.label === "Calendar") {
          return null;
        }

        return (
          <li key={index}>
            <NavLink
              className={({ isActive, isPending }) =>
                isPending ? "" : isActive ? "active" : ""
              }
              to={route.path}
            >
              {route.label}
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
};

export default PrivateMenu;
