import axios from "axios";
import { BASE_URL,APIENDPOINTS,PAYMENT } from './url_api';

// default
axios.defaults.baseURL = BASE_URL;
const instance = axios.create({
  baseURL: BASE_URL,
});

// content type
axios.defaults.headers.post["Accept"] = "application/json";
// axios.defaults.headers.post["Authorization"] = "test-secret-key-92c78feb604a47cba538951c579859cc";

instance.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('auth_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// intercepting to capture errors
axios.interceptors.response.use(
    function (response) {
      return response.data ? response.data : response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      let message;
      switch (error.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 404:
          message = "Sorry! the data you are looking for could not be found";
          break;
        default:
          message = error?.response || error;
      }
      return Promise.reject(message);
    }
  );


  class API {

    post = (url, data) => {
        return axios.post(APIENDPOINTS[url], data);
    };

    delete = (url, id) => {
        return axios.delete(`${APIENDPOINTS[url]}/${id}`);
    };

    paymentPost = (url, data) => {
        return axios.post(PAYMENT[url], data);
    };

    get = (url) => {
        return axios.get(APIENDPOINTS[url]);
    }

    getById = (url,id) => {
        return axios.get(`${APIENDPOINTS[url]}${id}`);
    }

    put = (url, id, data) => {
        return axios.put(`${APIENDPOINTS[url]}/${id}`, data);
    }

    getCountry = (url,latitude,longitude) => {
        return axios.get(`${APIENDPOINTS[url]}?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`);
    }

  }
  const setAuthorization = (token) => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  };

  export { API,setAuthorization };