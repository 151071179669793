/* eslint-disable no-undef */
import React from "react";
import Body from './common/Body'
import { useForm } from "react-hook-form";
import { API, setAuthorization } from '../helper/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const api = new API();

const ForgotPassword = () => {
    const { t } = useTranslation()
    const { handleSubmit, register, formState: { errors } } = useForm();
    const navigate = useNavigate();

    const onSubmit = async (values) => {
        try {
            console.log(values);
            const response = await api.getById('FORGOT_PWD', values.email);
            if(response.status) {
                toast.success(response.message);
                setTimeout(() => {
                    navigate("/login");
                }, 1000);
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.error(error);
            toast.error(error?.data?.message);
        }
    }

    return (
        <Body>
            <ToastContainer closeButton={false} limit={1} />
            <section className="belford-register-hero-section belford-form-section" id="belford-register-hero-section">
                <div className="container">
                    <div className="row gy-4 justify-content-center align-items-center">
                        <div className="col">
                            <div className="belford-register-box text-center">
                                <h1> <span>Forgot Password</span> </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="belford-form-section belford-register-form-section" id="belford-register-section">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-6 col-xs-12">
                            <div className="belford-form-box">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row g-4">
                                        <div className="col-12 col-md-12">
                                            <div className="form">
                                                <label htmlFor="email" className="form-label">{t("loginData.formSection.Email")}</label>
                                                <input type="email" className="form-control" id="email" placeholder="yourmail@gmail.com" aria-invalid={errors.email ? "true" : "false"} {...register("email",
                                                    {
                                                        required: "Email is required",
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            message: "Invalid email address"
                                                        }
                                                    })} />
                                            </div>
                                            {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                                        </div>
                                        <div className="col-12">
                                            <div className="form text-center mb-2">
                                                <button type="submit" className="belford-btn">Submit</button>
                                            </div>
                                        </div>
                                        <div id="checkout-container-div"></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Body>
    )
}

export default ForgotPassword