/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Body from "./common/Body";
import img11 from "../assets/images/img-11.png";
import img12 from "../assets/images/img-12.png";
import img13 from "../assets/images/img-13.png";
import img14 from "../assets/images/img-14.png";
import img15 from "../assets/images/jenuta.jpg";
import img16 from "../assets/images/img-16.png";
import img17 from "../assets/images/aquila.jpg";
import slider1 from "../assets/images/slider-1.png";
import slider2 from "../assets/images/slider-2.png";
import tempbanner from "../assets/images/tempbanner.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import video1 from "../assets/videos/VID-20240423-WA0002.mp4";
import video2 from "../assets/videos/VID-20240517-WA0003.mp4";
import video3 from "../assets/videos/VID-20240420-WA0005.mp4";
import video4 from "../assets/videos/VID-20240520-WA0007.mp4";
import video5 from "../assets/videos/VID-20240424-WA0008.mp4";
import video6 from "../assets/videos/VID-20240422-WA0012.mp4";
import video7 from "../assets/videos/VID-20240416-WA0019.mp4";
import upreviews from "../assets/videos/updated-reviews.mp4";
import videoicon from "../assets/images/video-play.svg";





const Home = () => {
  const { t } = useTranslation();
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const sliders = [
    { img: slider1, url: "/register" },
    { img: slider2, url: "/register" },
  ];

  return (
    <Body>
      <section className="belford-hero-section" id="belford-hero-section">
        <Slider className="belford-hero-slider" {...sliderSettings}>
          {sliders.map((slider, i) => (
            <div key={i + 1}>
              <div
                className="belford-hero-slider-inner slider"
                style={{ "background-image": `url(${slider.img})` }}
              >
                {/* {i > 0 ? ( */}
                {/* ) : ( */}
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className="belford-hero-slider-box">
                        <h3>
                          {t(`homeData.heroSection.sliderBox${i + 1}.H3Text`)}
                        </h3>
                        <h1>
                          {t(`homeData.heroSection.sliderBox${i + 1}.H1Text`)}
                        </h1>
                        {/* <p>{t(`homeData.heroSection.sliderBox${i + 1}.PText`)}</p> */}
                        <Link to={slider.url} className="belford-btn">
                          {t(`homeData.heroSection.sliderBox${i + 1}.LinkText`)}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* )} */}
              </div>
            </div>
          ))}
        </Slider>
      </section>
      <section
        className="home-page belford-start-now-pricing-section"
        id="belford-start-now-pricing-section"
      >
        <div className="container">
          <div className="row gy-4 justify-content-center">
            <div className="col-lg-6">
              <div className="belford-start-now-pricing-box start-now-yellow-color">
                <div>
                  <div className="start-now-pricing-title">
                    <h4>{t("pricing_table.golden.title")}</h4>
                  </div>
                  <ul className="start-now-pricing-inner">
                    <li>{t("pricing_table.golden.title")}</li>
                    <li>{t("pricing_table.golden.list1")}</li>
                    <li>{t("pricing_table.golden.list2")}</li>
                    <li>{t("pricing_table.golden.list3")}</li>
                  </ul>
                </div>
                <div className="start-now-pricing-info">
                  <h4>COSTS</h4>
                  <p>{t("pricing_table.golden.list4")}</p>
                  <p>{t("pricing_table.golden.list5")}</p>
                  <p>{t("pricing_table.golden.list6")}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="belford-start-now-pricing-box start-now-blue-color">
                <div>
                  <div className="start-now-pricing-title">
                    <h4>{t("pricing_table.blue.title")}</h4>
                  </div>
                  <ul className="start-now-pricing-inner">
                    <li>{t("pricing_table.blue.title")}</li>
                    <li>{t("pricing_table.blue.list1")}</li>
                    <li>{t("pricing_table.blue.list2")}</li>
                  </ul>
                </div>
                <div className="start-now-pricing-info">
                  <h4>COSTS</h4>
                  <p>{t("pricing_table.blue.list3")}</p>
                  <p>{t("pricing_table.blue.list4")}</p>
                  <p>{t("pricing_table.blue.list5")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
       <section className="belford-about-section vs-has-video" id="belford-about-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-12">
                <Link to="/contact"><img src={tempbanner} style={{ minWidth: '100%' }}/></Link>
            </div>
          </div>
        </div>
      </section>
      <section className="belford-about-section vs-has-video" id="belford-about-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-6">
              <div className="belford-about-video">
                <div
                  className="belford-about-bg-video"
                  //   style="background-image: url(https://unsplash.imgix.net/photo-1425036458755-dc303a604201?fit=crop&fm=jpg&q=75&w=1000);"
                >
                  <div className="belford-about-bt-play"></div>
                </div>
                <div className="video-container">
                  <iframe
                        src="https://player.vimeo.com/video/1005451233?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                        title="bws video 1"
                      >
                    </iframe>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="belford-about-content">
                <h2>
                  <span>
                    {" "}
                    {t("homeData.aboutSection.aboutContent.H2Text.SpanText")}
                  </span>
                  &nbsp;
                  {t("homeData.aboutSection.aboutContent.H2Text.textAfterSpan")}
                </h2>
                <p>{t("homeData.aboutSection.aboutContent.PText1.Text")}</p>
                <p>
                  {t("homeData.aboutSection.aboutContent.PText2.Text")}
                  <strong>
                    {t("homeData.aboutSection.aboutContent.PText2.strongText")}
                  </strong>
                  {t(
                    "homeData.aboutSection.aboutContent.PText2.textAfterStrong"
                  )}
                </p>
                <p>
                  {t("homeData.aboutSection.aboutContent.PText3.Text")}
                  <strong>
                    {t("homeData.aboutSection.aboutContent.PText3.strongText")}
                  </strong>
                  {t(
                    "homeData.aboutSection.aboutContent.PText3.textAfterStrong"
                  )}
                </p>
                <p>
                  {t("homeData.aboutSection.aboutContent.PText4.Text")}
                  <strong>
                    {t("homeData.aboutSection.aboutContent.PText4.strongText")}
                  </strong>
                  {t(
                    "homeData.aboutSection.aboutContent.PText4.textAfterStrong"
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="belford-course-section vs-has-video" id="belford-course-section">
        <div className="container">
          <div className="belford-course-box">
            <div className="row align-items-center">
              <div className="col-12 col-lg-6">
                <div className="belford-course-content">
                  <div className="belford-course-content-inner">
                    <h4>{t("homeData.courseSection.H4Text")}</h4>
                    <h2>{t("homeData.courseSection.H2Text")}</h2>
                    <p>{t("homeData.courseSection.PText")}</p>
                  </div>
                  <div className="belford-course-content-box">
                    <div className="belford-course-content-inner text-center">
                      <img src={img11} alt="img11" />
                      <h5>{t("homeData.courseSection.contentBox.Box1")}</h5>
                    </div>
                    <div className="belford-course-content-inner text-center">
                      <img src={img12} alt="img12" />
                      <h5>{t("homeData.courseSection.contentBox.Box2")}</h5>
                    </div>
                    <div className="belford-course-content-inner text-center">
                      <img src={img13} alt="img13" />
                      <h5>{t("homeData.courseSection.contentBox.Box3")}</h5>
                    </div>
                  </div>
                  <div className="belford-course-btn">
                    <Link to="/contact" className="belford-btn">
                      {t("homeData.courseSection.courseBtn.LinkText")}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="belford-course-video">
                  <div className="belford-course-bg-video">
                    <div className="belford-course-bt-play"></div>
                  </div>
                  <div className="video-container">
                    <iframe
                        src="https://player.vimeo.com/video/1005451144?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                        title="bws video 1"
                      >
                    </iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="belford-trusted-section" id="belford-trusted-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="belford-trusted-heading text-center">
                <h2>{t("homeData.trustedSection.H2Text")}</h2>
              </div>
            </div>
            <div className="col-12">
              <div className="belford-trusted-logo">
                <div className="belford-trusted-logo-box">
                  <img src={img14} alt="img14" />
                </div>
                <div className="belford-trusted-logo-box">
                  <img src={img15} alt="img15" width="133px" height="52px" />
                </div>
                <div className="belford-trusted-logo-box">
                  <img src={img16} alt="img16" />
                </div>
                <div className="belford-trusted-logo-box">
                  <img src={img17} alt="img17" width="133px" height="52px" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <div className="vs-home-videos-section">
      <div className="container">
        <h2>
          See what our Students <br /> are Saying
        </h2>
        <div className="vs-home-videos-slider">
          <div className="vs-home-videos-item">
            <video controls id="video1">
              <source src={video1} type="video/mp4" />
            </video>
            <img
              src={videoicon}
              alt="Play Video"
              className="play-button"
              data-video="video1"
            />
          </div>
          <div className="vs-home-videos-item">
            <video controls id="video2">
              <source src={video2} type="video/mp4" />
            </video>
            <img
              src={videoicon}
              alt="Play Video"
              className="play-button"
              data-video="video2"
            />
          </div>
          <div className="vs-home-videos-item">
            <video controls id="video3">
              <source src={video3} type="video/mp4" />
            </video>
            <img
              src={videoicon}
              alt="Play Video"
              className="play-button"
              data-video="video3"
            />
          </div>
          <div className="vs-home-videos-item">
            <video controls id="video4">
              <source src={video4} type="video/mp4" />
            </video>
            <img
              src={videoicon}
              alt="Play Video"
              className="play-button"
              data-video="video4"
            />
          </div>

          <div className="vs-home-videos-item">
            <video controls id="video5">
              <source src={video5} type="video/mp4" />
            </video>
            <img
              src={videoicon}
              alt="Play Video"
              className="play-button"
              data-video="video5"
            />
          </div>
          <div className="vs-home-videos-item">
            <video controls id="video6">
              <source src={video6} type="video/mp4" />
            </video>
            <img
              src={videoicon}
              alt="Play Video"
              className="play-button"
              data-video="video6"
            />
          </div>
          <div className="vs-home-videos-item">
            <video controls id="video7">
              <source src={video7} type="video/mp4" />
            </video>
            <img
              src={videoicon}
              alt="Play Video"
              className="play-button"
              data-video="video7"
            />
          </div>
        </div>
      </div>
    </div>

    </Body>
  );
};

export default Home;
