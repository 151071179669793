export const common = {
    socialLink: {
        instagram: 'https://www.instagram.com/belfordwoodsschool/',
        facebook: 'https://www.facebook.com/profile.php?id=100085883467611',
        linkedin: 'https://www.linkedin.com/company/belford-woods-school-%E2%80%93-formazione-di-lingua-inglese-online/?originalSubdomain=it',
    },
    footerData: {
        "topContent": {
            "H2Text": "A bit of information",
            "PText1": "Among the most spoken languages in the world, there is English. Among the people who use it as a first or second language, we reach a total of approximately 1.5 billion speakers in 24 countries.",
            "PText2": "The best universities in the world are located in countries where English is the native language, or they offer courses of global excellence in English.",
            "PText3": "The best job opportunities undoubtedly require an advanced knowledge of the English language.",
            "belford-btn": {
                "Text": "Start now your free week trial"
            }
        },
        "footerMiddle": {
            "H2Text": "Sign up to The Platform now",
            "PText": " The English course that offers you the best learning experience designed for you and your success",
            "belford-btn": {
                "Text": "Sign Up Now"
            }
        },
        "footerBottom": {
            "PText1": "Via Riccardo Grazioli Lante 15/A 00195 Rome, Italy",
            "PText2": "Privacy Policy",
            "PText3": "Terms and Conditions"

        },
        "copyRight": {
            "PText": {
                "Text": "©",
                "anchorText": " Belford Woods School ",
                "textAfterStrong": "Registered Trademark 2023 P.I.17343501007"
            }
        }
    },
    header: {
        language: [{
            "EN": 'EN',
            "IT": 'IT',
            "FR": 'FR',
            "ES": 'ES',
        }]
    },
    corporateData: {
        heroSection: {
            "H1Text": "Corporate Courses",
            "PText": "Global markets and exchanges require a strong language knowledge. Even today, however, not everyone has the adequate skills to spend in such a competitive professional world. Belford Woods School has been providing corporate English and Italian courses for years, dedicated to active and passionate companies that are engaging a great competition in their own specific fields."
        },
        aboutSection: {
            "contentList1": "We start analyzing the company sector and desired objectives.",
            "contentList2": "Together with the entrepreneur and the HR office, we examine the current English or Italian level demonstrated by the staff.",
            "contentList3": "Belford Woods School Corporate Office works on a tailored-made programme that will be evaluated by the company.",
            "contentList4": "Once approved, the study programme of the corporate course will be developed with the staff.",
            "contentList5": "Belford Woods School can operate in presence and online to provide the best possible service to the client. In all cases, our teachers – normally native speakers – possess all needed professional requirements to meet the company’s ambitious goals."
        }
    },
    ItalianData: {
        heroSection: {
            "H1Text": "Italian for foreigners",
        },
        aboutSection: {
            "PText": "Have you ever wanted to test your abilities and start learning the language of the Dolce Vita? Embrace Fellini, Leonardo da Vinci,Puccini and Benigni in their native soft sounds. Our courses are stress-freeand very easy to access. You can start when you want together with native Italian teachers. You will be provided with a tailored-made study programme and in your 1 to 1 classes you will have at your disposal a selection of the best Italian language teachers that will help you navigate the structured yet wonderful language of the Bel Paese."
        }
    },
    homeData: {
        heroSection: {
            sliderBox1: {
                "H3Text": "Welcome to Belford Woods School Your Online English School",
                "H1Text": "Learn English and take off towards your future",
                "PText": "Start with your free week with 1-1 className",
                "LinkText": " Start now your free week trial"
            },
            sliderBox2: {
                "H3Text": "Welcome to Belford Woods School Your Online English School",
                "H1Text": "Learn English and take off towards your future",
                "PText": "Start with your free week with 1-1 className",
                "LinkText": " Start now your free week trial"
            },
            sliderBox3: {
                "H3Text": "Welcome to Belford Woods School Your Online English School",
                "H1Text": "Learn English and take off towards your future",
                "PText": "Start with your free week with 1-1 className",
                "LinkText": " Start now your free week trial"
            }
        },
        aboutSection: {
            aboutContent: {
                H2Text: {
                    "SpanText": "Your English",
                    "textAfterSpan": "course"
                },
                PText1: {
                    "Text": "Flexible, moms-friendly, worker-friendly. The English course that makes you successful is finally available."
                },
                PText2: {
                    "Text": "With The Platform you will have the",
                    "strongText": "opportunity",
                    "textAfterStrong": "to follow the learning path best suited to you, developed based on your schedule and needs."
                },
                PText3: {
                    "Text": "You will participate in",
                    "strongText": "1-1 and small group",
                    "textAfterStrong": "lessons, while learning from always up-to-date and easy-to-use materials."
                },
                PText4: {
                    "Text": "Our English course is a",
                    "strongText": "cutting-edge learning experience",
                    "textAfterStrong": "that enhances the capabilities of every human being based on everybody's specific qualities."
                }
            }
        },
        courseSection: {
            "H4Text": "The English course",
            "H2Text": "Where the student is at centre stage",
            "PText": "We have everything you need to learn English.",
            contentBox: {
                "Box1": "Easy",
                "Box2": "Direct",
                "Box3": "Successful"
            },
            courseBtn: {
                "LinkText": " Contact us"
            }
        },
        trustedSection: {
            "H2Text": "Trusted By"
        }
    },
    platformData: {
        heroSection: {
            "H1Text": " Test Your English Level Start Your English Course 1 Free Week with 1-1 Class",
            "AText": "Start now your free week trial"
        },
        aboutSection: {
            aboutTitle: {
                H2Text: {
                    "Text": " What is The",
                    "SpanText": "Platform?"
                },
            },
            aboutContent: {
                "PText1": "The Platform at Belford Woods School is the best platform in the world for learning the English language.",
                "PText2": " Inside The Platform you will find vocabulary, readings, grammar files, interactive exercises and the possibility of practicing with native or bilingual teachers for the speaking section.",
                "PText3": " The Platform is the ultimate English course that will take you to the future through success.",
                "PText4": " After years of experience in teaching the English language, our team of teachers has finally developed the most complete, easy-to-use, engaging learning platform. Our English course on The Platform will give you the opportunity to improve all your English skills, reaching the level you have always wanted.",
            }
        },
        serviceSection: {
            serviceHeader: {
                "H2Text": "How does it work?"
            },
            serviceBox: {
                Box1: {
                    "H5Text": "01",
                    "H4Text": "Take the free test to evaluate your level"
                },
                Box2: {
                    "H5Text": "02",
                    "H4Text": "Enter The Platform"
                },
                Box3: {
                    "H5Text": "03",
                    "H4Text": " start your English course immediately with a free one-week trial"
                }
            },
            servicePlatformBtn: {
                "LinkText": "Contact us",
                "PText": " During the trial week you will also be able to participate in a 1-1 lesson and a small group lesson"
            }
        },
        subscriptionsSection: {
            subscriptionsBox: {
                H2Text: {
                    "Text": "Our",
                    "SpanText": "subscriptions"
                },
                "LinkText1": "Golden Ribbon",
                "PText1": "You can choose between two types of subscriptions:",
                "PText2": " In The Platform you will find 20 Units for each level, each with grammar, vocabulary, reading and writing files. You will be able to book two 1-1 lessons per month and two in small groups.",
                "LinkText2": "Golden Ribbon",
                "PText3": "  You will have all the contents of The Platform available, except the teacher-led lessons.",
                "PText4": " At the end of the 20 Units, regardless the Ribbon chosen you will have the opportunity to book your exam with a teacher to obtain certification."
            },
            subscriptionsBtn: {
                "LinkText": "Contact us"
            }
        },
        platformSection: {
            platformHeader: {
                H2Text: {
                    "Text": "course",
                    "SpanText": "The rules of"
                },
            },
            platformbox: {
                "PText1": "We offer monthly subscriptions Golden or Blue Ribbon after the 1 week free trial.",
                "PText2": "Once you enter The Platform, you will find the first Unit available.Once you have completed the Unit in all its sections, the following Unit will automatically unlock and so on",
                "PText3": "At the end of Unit 10, you will have to take a short online recap test of the previous Units. This test is considered successfully passed with 60% of correct answers.",
                "PText4": " If you opt for the Golden Ribbon, you can book up to two 1-1 classes a week for a maximum of two per month. In addition, you can book a place during two group classes.",
                "PText5": " Finally, once all 20 Units have been successfully completed, you can book through the calendar your examination with a teacher. After the exam, you will receive your English Fcertificate for your level.",
                "PText6": "You can interrupt your subscription at any time."
            }
        }
    },
    shopData: {
        heroSection: {
            "H1Text": "Belford Woods School",
            "SpanText": "A world full of Value",
            "PText": "Our books that will help you during your learning journey are just one click away from you. Your English course gets wider and wider with these great contents you can use everywhere at any time."
        },
        shopSection: {
            Box1: {
                shopcontent: {
                    "H4Text": "English Horizon",
                    "PText": "Corso di Lingua Inglese Per Te Copertina"
                },
                shopbtn: {
                    "LinkText": "Buy Now"
                }
            },
            Box2: {
                shopcontent: {
                    "H4Text": "English Horizon",
                    "PText": "Corso di Lingua Inglese Per Te Copertina"
                },
                shopbtn: {
                    "LinkText": "Buy Now"
                }
            },
            Box3: {
                shopcontent: {
                    "H4Text": "English Horizon",
                    "PText": "Corso di Lingua Inglese Per Te Copertina"
                },
                shopbtn: {
                    "LinkText": "Buy Now"
                }
            },
            Box4: {
                shopcontent: {
                    "H4Text": "English Horizon",
                    "PText": "Corso di Lingua Inglese Per Te Copertina"
                },
                shopbtn: {
                    "LinkText": "Buy Now"
                }
            },
        }
    },
    serviceData: {
        heroSection: {
            H1Text: {
                "Text": "This is how we",
                "SpanText": "help your business."
            },
            belfordbtn: {
                "Text": "Click here for further information"
            }
        },
        courseSection: {
            H2Text: {
                "Text": " English Courses",
                "SpanText": "Corporate"
            },
            "PText": "After years of experience in different sectors and industries, Belford Woods School has designed specific English courses dedicated to employees of all types of companies, aiming to improve and strengthen their English language skills. We deliver corporate English courses through a joint work with the client itself, in order to put in place the best possible tailor-made English course.",
            courselist: {
                "list1": "Discretion,",
                "list2": "Professionality,",
                "list3": "Security",
            }
        },
        furtherSection: {
            furtherbox: {
                Box1: {
                    H2Text: {
                        "SpanText": "Translation"
                    },
                    "PText": "Whatever the document and the industry, we are able to deliver a precise and accurate translation. This is a great skills<br></br>Belford Woods School has developed during years of assistance to different types of companies.",
                    belfordbtn: {
                        "Text": "Click here for further information"
                    }
                },
                Box2: {
                    H2Text: {
                        "SpanText": "Legal"
                    },
                    "PText": "In order to deliver a real full service package, we not only take care of the entrepreneur’s needs under a linguistic point of view, but we can also suggest some of the most hard-working and successful corporate lawyers in Milan. The company owner will be assisted at our best potential.",
                    belfordbtn: {
                        "Text": "Click here for further information"
                    }
                }
            }
        }
    },
    translationData: {
        heroSection: {
            "H1Text": "Translation",
            "PText": "We make all documents accessible",
            belfordbtn: {
                "Text": "Click here for further information"
            }
        },
        aboutSection: {
            aboutTitleTranslation: {
                H2Text: {
                    "Text": " an easy access to a new type of translation ",
                    "SpanText": "An immense range of languages,"
                },
            },
            aboutContent1: {
                "PText1": "Books, novels, newspapers, magazines, to documents and formal papers. There is no written form of communication we can’t deal with. Belford Woods School can assist all customers along the translation procedure, delivering in a very short time the best possible product.",
                "PText2": "Everything clear – we evaluate the text – we send you a quotation – we start working – you receive your translated text.",
            },
            aboutTranslationBtn: {
                "Text": "Confidentiality is the key"
            },
            aboutContent2: {
                "PText": ">We are proud to say that discretion is our main characteristic. Nothing is ever released or published. All information received is constantly protected and kept in a safe place."
            }

        }
    },
    AboutUsData: {
        heroSection: {
            H1Text: "Valentina Grassi",
            H3Text: "Is the founder of Belford Woods School and The Platform.",
        },
        aboutSection: {
            "PText1": {
                "Text": "She was",
                "strongText1": "born in Italy",
                "textAfterStrong1": "but raised abroad, among ",
                "strongText2": "Belgium, the US, Pakistan, Germany",
                "textAfterStrong2": "and many other countries. She worked in Luxemburg, Italy and Israel as Legal counsel."
            },
            "PText2": {
                "Text": "Valentina is also a former",
                "strongText1": "Paralympic athlete",
                "textAfterStrong1": "in the Italian rowing team. She has won several international medals among which we find the",
                "strongText2": "Bronze Medal",
                "textAfterStrong2": " at the 2014 Amsterdam World Rowing Championships and the Silver Medal in the 2014 Aiguebelette World Rowing Cup."
            },
            "PText3": {
                "Text": "Valentina is actually ",
                "strongText1": "English and Italian native,",
                "textAfterStrong1": "with a professional knowledge of",
                "strongText2": "French and Spanish",
                "textAfterStrong2": "and an intermediate knowledge of",
                "strongText3": "Russian and Dutch."
            },
            "PText4": "Thanks to all her different life and work experiences, Valentina has been able to develop a teaching method that can address thoroughly the never-solved difficulties of non-native English speakers. She firmly believes that practice makes perfection, therefore all contents are designed in order to make the students practice as much as possible.",
            "PText5": "Valentina is actively involved in charity projects in favour of people with disability, especially children and young adults."
        }
    },
    ContactData: {
        heroSection: {
            H1Text: "For all information",
            H3Text: "fPlease fill in the contact form to receive assistance.",
        },
        formSection: {
            "firstName": "First name",
            "lastName": "Last name",
            "Email": "Email",
            "Country": "Country",
            "Phone": "Phone",
            "Howdidyoufindus": "How did you find us?",
            "Message": "Message",
            "checkbox": {
                "Text": "Please note that information regarding",
                "AnchorText": "privacy and data privacy",
                "textAfterAnchor": "consent is mandatory *"
            },
            belfordBtn: {
                "Text": "Send"
            }
        }
    },
    certificateData: {
        heroSection: {
            H1Text: "Get Your English Certificate with Belford Woods School Online",
        },
        aboutSection: {
            "PText1": {
                "Text": "If you need an",
                "strongText1": "English language certificate",
                "textAfterStrong": ", contact us to get the useful information concerning our awards.",
            },
            "PText2": "We can quickly arrange your online exam and deliver the certification in a very short time.",
            "PText3": "Fill in the form in order to receive all additional details."
        },
        formSection: {
            "formHeading": "Contact us Now",
            "firstName": "First name",
            "lastName": "Last name",
            "Email": "Email",
            "Country": "Country",
            "Phone": "Phone",
            "Howdidyoufindus": "How did you find us?",
            "Message": "Message",
            "checkbox": {
                "Text": "Please note that information regarding",
                "AnchorText": "privacy and data privacy",
                "textAfterAnchor": "consent is mandatory *"
            },
            belfordBtn: {
                "Text": "Send"
            }
        }
    }
};

