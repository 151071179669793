import React from 'react';
import { Navigate } from 'react-router-dom';
import { setAuthorization } from './api';


const AuthInterceptor = (props) => {
  const isAuthenticated = sessionStorage.getItem('auth_token');
  if (isAuthenticated) {
    setAuthorization(isAuthenticated)
  }
  return isAuthenticated ? props.children : <Navigate to="/login" />
}

export default AuthInterceptor;

export const PublicInterceptor = (props) => {
  const isAuthenticated = sessionStorage.getItem('auth_token');
  if (isAuthenticated) {
    setAuthorization(isAuthenticated)
  }   
  return isAuthenticated ? <Navigate to="/welcome" /> : props.children
}

export const isAuthenticated = () => {
  const isAuthenticated = sessionStorage.getItem('auth_token');
  return isAuthenticated ? true : false;
}

export const getCurrentUser = () => {
  const currentUser = sessionStorage.getItem('current_user');
  console.log(currentUser);
  return currentUser ? JSON.parse(currentUser) : <Navigate to="/login" />;
}

export const getLoggendUser = () => {
  const currentLoignUser = sessionStorage.getItem('current_login_user');
  return currentLoignUser ? JSON.parse(currentLoignUser) : <Navigate to="/login" />;
}