import React from 'react'
import Body from './common/Body'
import imgstartnow1 from "../assets/images/img-start-now-1.png"
import imgstartnow2 from "../assets/images/img-start-now-2.png"
import imgstartnow3 from "../assets/images/img-start-now-3.png"
import imgstartnow4 from "../assets/images/img-start-now-4.png"
import imgstartnow5 from "../assets/images/img-start-now-5.png"
import imgstartnow6 from "../assets/images/img-start-now-6.png"
import { toast } from 'react-toastify'
import { API } from '../helper/api'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

const api = new API();

const Start = () => {
    const { handleSubmit, register, formState: { errors }, getValues } = useForm();
    const navigate = useNavigate();

    const onSubmit = async (values) => {
        try {
            const response = await api.post('GUEST_USER', values);
            if (response.status === true) {
                sessionStorage.setItem('guest_user', JSON.stringify(response.data));
                toast.success('Guest User has been done successfully.');
                setTimeout(() => { navigate("/Test") }, 3000);
            }
        } catch (error) {
            console.error('Error while Guest User', error);
            toast.error(error);
        }
    }
    return (
        <Body>
            <section className="belford-start-now-hero-section belford-form-section" id="belford-start-now-hero-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="row gy-4 justify-content-center align-items-center">
                                <div className="col-lg-6">
                                    <div className="belford-italian-box">
                                        <h1> <span>TAKE YOUR FREE EVALUATION TEST </span> </h1>
                                        <h3>AND START YOUR FIRST FREE WEEK AT THE PLATFORM</h3>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="belford-founder-image justify-content-center d-flex align-items-center">
                                        <img src={imgstartnow1} alt='imgstartnow1' />
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-12">
                                    <div className="belford-form-box">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="row g-4">
                                                <div className="col-12 col-md-6">
                                                    <div className="form">
                                                        <label htmlFor="first_name" className="form-label">First name</label>
                                                        <input type="text" className="form-control" id="first_name" placeholder="Name" aria-invalid={errors.firstname ? "true" : "false"} {...register("firstname", {
                                                            required: "Firstname is required", pattern: {
                                                                value: /^[A-Za-z]+$/,
                                                                message: "Only alphabets are allowed"
                                                            }
                                                        })} />
                                                    </div>
                                                    {errors.firstname && <div className="invalid-feedback">{errors.firstname.message}</div>}
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="form">
                                                        <label htmlFor="last_name" className="form-label">Last name</label>
                                                        <input type="text" className="form-control" id="last_name" placeholder="Last Name" aria-invalid={errors.lastname ? "true" : "false"} {...register("lastname", {
                                                            required: "Lastname is required", pattern: {
                                                                value: /^[A-Za-z]+$/,
                                                                message: "Only alphabets are allowed"
                                                            }
                                                        })} />
                                                    </div>
                                                    {errors.lastname && <div className="invalid-feedback">{errors.lastname.message}</div>}
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="form">
                                                        <label htmlFor="email" className="form-label">Email</label>
                                                        <input type="email" className="form-control" id="email" placeholder="yourmail@gmail.com" aria-invalid={errors.email ? "true" : "false"} {...register("email",
                                                            {
                                                                required: "Email is required",
                                                                pattern: {
                                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                    message: "Invalid email address"
                                                                }
                                                            })} />
                                                    </div>
                                                    {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                                                </div>
                                                <div className="col-12">
                                                    <div className="form text-center">
                                                        <button type="submit" className="belford-btn">Take The test</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="belford-start-now-about-section d-lg-block d-none" id="belford-start-now-about-section">
                <div className="container">
                    <div className="text-center">
                        <h2>What You Will Have</h2>
                    </div>
                    <div className="row justify-content-center gy-4">
                        <div className="col">
                            <div className="start-now-about-box text-center">
                                <img src={imgstartnow2} alt='imgstartnow2' />
                                <p>A free test to evaluate your exact English level</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="start-now-about-box text-center">
                                <img src={imgstartnow6} alt='imgstartnow6' />
                                <p>Access to the contents of The Platform FREE For one week</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="start-now-about-box text-center">
                                <img src={imgstartnow5} alt='imgstartnow5' />
                                <p>One FREE 1-1 online className with a English teacher</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="start-now-about-box text-center">
                                <img src={imgstartnow3} alt='imgstartnow3' />
                                <p>Two great alternative subscriptions you can stop at any time</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="start-now-about-box text-center">
                                <img src={imgstartnow4} alt='imgstartnow4' />
                                <p>Continuous online assistance</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="belford-start-now-about-section d-lg-none d-md-block" id="belford-start-now-about-section">
                <div className="container">
                    <div className="text-center">
                        <h2>What You Will Have</h2>
                    </div>
                    <div className="row justify-content-center gy-4">
                        <div className="col-md-6 col-sm-12">
                            <div className="start-now-about-box text-center">
                                <img src={imgstartnow2} alt='imgstartnow2' />
                                <p>A free test to evaluate your exact English level</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="start-now-about-box text-center">
                                <img src={imgstartnow6} alt='imgstartnow6' />
                                <p>A free test to evaluate your exact English level</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="start-now-about-box text-center">
                                <img src={imgstartnow5} alt='imgstartnow5' />
                                <p>A free test to evaluate your exact English level</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="start-now-about-box text-center">
                                <img src={imgstartnow3} alt='imgstartnow3' />
                                <p>A free test to evaluate your exact English level</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="start-now-about-box text-center">
                                <img src={imgstartnow4} alt='imgstartnow4' />
                                <p>A free test to evaluate your exact English level</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="belford-start-now-pricing-section" id="belford-start-now-pricing-section">
                <div className="container">
                    <div className="row gy-4 justify-content-center">
                        <div className="col-lg-6">
                            <div className="belford-start-now-pricing-box start-now-yellow-color">
                                <div>
                                    <div className="start-now-pricing-title">
                                        <h4>Golden Ribbon</h4>
                                    </div>
                                    <ul className="start-now-pricing-inner">
                                        <li>All contents of The Platform</li>
                                        <li>Two 1-1 online classes per month</li>
                                        <li>Two small group online classes per month</li>
                                        <li>24/7 online assistance</li>
                                        <li>Final Certificate</li>
                                    </ul>
                                </div>
                                <div className="start-now-pricing-info">
                                    <h4>COSTS</h4>
                                    <p>Subscription Fee €50,00</p>
                                    <p>Monthly Fee €140,00 instead of €195,00</p>
                                    <p>Certificate €150,00</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="belford-start-now-pricing-box start-now-blue-color">
                                <div>
                                    <div className="start-now-pricing-title">
                                        <h4>Blue Ribbon</h4>
                                    </div>
                                    <ul className="start-now-pricing-inner">
                                        <li>All contents of The Platform</li>
                                        <li>24/7 online assistance</li>
                                        <li>Final Certificate</li>
                                    </ul>
                                </div>
                                <div className="start-now-pricing-info">
                                    <h4>COSTS</h4>
                                    <p>Subscription Fee €50,00</p>
                                    <p>Monthly Fee €80,00 instead of €115,00</p>
                                    <p>Certificate €150,00</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Body>
    )
}

export default Start