/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { API } from '../helper/api';
const api = new API();
const Payment = () => {
  useEffect(() => {
    handlePayment()
})

  const handlePayment = async () => {
      const payload = {
        "order": {
            "items": [
                {
                    "reference": "demo",
                    "name": "demo",
                    "quantity": 0.1,
                    "unit": "string",
                    "unitPrice": 0,
                    "taxRate": 15*100,
                    "taxAmount": 15*100,
                    "grossTotalAmount": 15*100,
                    "netTotalAmount": 15*100
                }
            ],
            "amount": 15*100,
            "currency": "USD"
        },
        "checkout": {
            "url" : "http://localhost:3000",
            "termsUrl": "http://google.com"
        }
    };
    const response = await api.paymentPost('PAYMENT_URL',payload);
    console.log(response);
    console.log(response['paymentId']);
    if(response['paymentId']){
        var checkoutOptions = {
            checkoutKey: "test-checkout-key-aaea7ae277f84c2da14da95c0c28d31b",
            paymentId : response['paymentId'],
            containerId : "checkout-container-div",
            language: "en-GB",
            theme: {
                buttonRadius: "5px"
            }
        };
        var checkout = new Dibs.Checkout(checkoutOptions);
        checkout.on('payment-completed', function (response) {
          console.log({response});
        });
    }
  };

  return (
    <>
      <div id="checkout-container-div"></div>
    </>
  );
};

export default Payment;
