import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AuthInterceptor from "../helper/Authorization";
import {PublicInterceptor} from "../helper/Authorization";
import {
  headerRouterConfig,
  authRouter,
  otherRouter,
  privateRoute,
  privateAuthRoute,
} from "./allRoutes";

const publicRoutes = [...headerRouterConfig, ...otherRouter];

const Index = () => {
  return (
    <>
      <Router>
        <Routes>
          {[...privateRoute, ...privateAuthRoute].map((route, idx) => (
            <Route
              path={route.path}
              element={<AuthInterceptor>{route.component}</AuthInterceptor>}
              key={idx}
              exact={true}
            />
          ))}
          {authRouter.map((route, idx) => (
            <Route
              path={route.path}
              element={<PublicInterceptor>{route.component}</PublicInterceptor>}
              key={idx}
              exact={true}
            />
          ))}
          {publicRoutes.map((route, idx) => (
            <React.Fragment key={idx}>
              {route?.children ? (
                route.children.map((childRoute, childIndex) => (
                  <Route
                  path={route.path+'/'+childRoute.path}
                  element={childRoute.component}
                  key={childIndex}
                  exact={true}
                />
                ))  
              ) : (
                <Route
                  path={route.path}
                  element={route.component}
                  key={idx}
                  exact={true}
                />
              )}
            </React.Fragment>
          ))}
        </Routes>
      </Router>
    </>
  );
};

export default Index;
