import React, { useEffect, useState } from "react";
import Body from "./common/Body";
import { ToastContainer, toast } from "react-toastify";
import PrivateMenu from "./common/PrivateMenu";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import { getCurrentUser } from "../helper/Authorization";
import "react-datepicker/dist/react-datepicker.css";
import { API } from "../helper/api";
import moment from "moment";
import { useNavigate } from 'react-router-dom';

function formatDate(inputString) {
  const parsedDate = new Date(inputString);
  const year = parsedDate.getFullYear();
  const month = String(parsedDate.getMonth() + 1).padStart(2, "0"); // Adding leading zero if needed
  const day = String(parsedDate.getDate()).padStart(2, "0"); // Adding leading zero if needed
  const hour = String(parsedDate.getHours()).padStart(2, "0"); // Adding leading zero if needed
  const minute = String(parsedDate.getMinutes()).padStart(2, "0"); // Adding leading zero if needed
  return `${year}-${month}-${day} ${hour}:${minute}`;
}
const api = new API();

function ExamAppointmentBooking() {
  const navigate = useNavigate();
  const currentUser = getCurrentUser();
  const [examDate, setExamDate] = useState({});
  const [privateDates, setPrivateDates] = useState([]);
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getCalendar();
  }, []);

  const getCalendar = async () => {
    try {
      const response = await api.get("CALENDER");
      if (response.status === true) {
        // if (response?.data?.calendar.length > 0) {
        //     response?.data?.calendar.forEach((booked) => {
        //         booked["start"] = moment(booked["start"]).toDate();
        //         booked["end"] = moment(booked["end"]).toDate();
        //     });
        // }
        // if (response?.data?.teacher.length > 0) {
        //     response?.data?.teacher.forEach((booked) => {
        //         booked["title"] = "Leave";
        //         booked["start"] = moment(booked["leave_start_date_time"]).toDate();
        //         booked["end"] = moment(booked["leave_end_date_time"]).toDate();
        //     });
        // }
        const eventDate = [
          ...response?.data?.calendar,
          ...response.data.teacher,
        ];

        setPrivateDates(
          response?.data?.calendar.filter(
            (event) => event.call_type === "individual" && event.students.length === 0
          )
        );
        console.log(privateDates);
      }
    } catch (error) {
      console.error("Error while get Calendar", error);
      toast.error(error?.data?.message);
    }
  };

  const onSubmit = async (values) => {
    // try {
    //   // const year = examDate.getFullYear();
    //   // const month = examDate.getMonth() + 1;
    //   // const day = examDate.getDate();
    //   // console.log(year, month, day, "fsdfs", formatDate(
    //   //     `${year} ${month} ${day} 00:00:00`
    //   // ))
    //   console.log(examDate);
    //   const request = {
    //     title: "",
    //     level_id: currentUser?.student?.level_id || 1,
    //     course_id: currentUser?.student?.course_id || 1,
    //     start_date_time: examDate.start,
    //     end_date_time: examDate.end,
    //     student_id: currentUser?.id,
    //     description: "",
    //     call_type: "final",
    //   };
    //   const response = await api.post("BOOK_APPOINTMENT", request);
    //   if (response.status === true) {
    //     setExamDate(new Date());
    //     toast.success("book appointment has been done successfully.");
    //   }
    // } catch (error) {
    //   console.error("Error while book appointment ", error);
    //   toast.error(error?.data?.message);
    // }

    try {
        console.log(examDate);
        const request = {
          "student_id": currentUser?.id
        }

        const response = await api.put(`BOOK_APPOINTMENT`, examDate, request);
        if (response.status === true) {
          toast.success("Book appointment has been done successfully.");
          navigate("/welcome");
          getCalendar();
        }
      } catch (error) {
        console.error("Error while book appointment ", error);
        toast.error(error?.data?.message);
      }
  };
  return (
    <Body>
      <ToastContainer closeButton={false} limit={1} />
      <section
        className="belford-withdraw-hero-section belford-withdraw-golden"
        id="belford-withdraw-hero-section"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8">
              <div className="belford-withdraw-hero-content text-center">
                <h1>
                  <span className="belford-title-yellow-color">
                    Book Your Final Exam
                  </span>
                </h1>
                <p>
                  Your Course{" "}
                  <span>
                    {currentUser !== null && currentUser.student.course.name}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="belford-translation-about-section"
        id="belford-translation-about-section"
      ></section>
      <section
        className="belford-form-section belford-register-form-section"
        id="belford-register-section"
      >
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-6">
              <div className="belford-form-box profile-box">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="">
                    <div className="form">
                      <label htmlFor="last_name" className="form-label">
                        Start date
                      </label>
                      <div>
                        {/* <DatePicker
                                                    className="form-control"
                                                    minDate={new Date()}
                                                    selected={examDate}
                                                    onChange={(date) => setExamDate(date)}
                                                /> */}
                        <select
                          className="form-select"
                          onChange={(e) => {
                            const selectedDate = privateDates.find(
                              (date) => date.start === e.target.value
                            );
                            if (selectedDate) {
                              setExamDate(selectedDate.id);
                            }
                          }}
                        >
                          {privateDates.map((date) => (
                            <option key={date.start} value={date.start}>
                              {date.start}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {errors.start_date_time && (
                      <div className="invalid-feedback">
                        {errors.start_date_time.message}
                      </div>
                    )}
                    <div className="col-12 mt-5">
                      <div className="form text-center">
                        <button type="submit" className="belford-btn">
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Body>
  );
}

export default ExamAppointmentBooking;
