/* eslint-disable no-undef */
import React from "react";
import Body from './common/Body'
import { useForm } from "react-hook-form";
import { API, setAuthorization } from '../helper/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const api = new API();

const Login = () => {
    const { t } = useTranslation()
    const { handleSubmit, register, formState: { errors } } = useForm();
    const navigate = useNavigate();

    const onSubmit = async (values) => {
        try {
            const response = await api.post('LOGIN', values);
            if (response.redirect) {
                window.location.href = response.redirectUrl;
            } else {
                toast.success('Login is successfully done.');
                sessionStorage.setItem('auth_token', response.access_token);
                setAuthorization(response.access_token);
                const userResponse = await api.get('USER');
                if(userResponse.status){
                    console.log(userResponse.data);
                    sessionStorage.setItem('current_login_user', JSON.stringify(response.user));
                    sessionStorage.setItem('current_user', JSON.stringify(userResponse.data));
                }
                navigate("/welcome");
            }
        } catch (error) {
            console.error(error);
            toast.error(error?.data?.message);
        }
    }

    return (
        <Body>
            <ToastContainer closeButton={false} limit={1} />
            <section className="belford-register-hero-section belford-form-section" id="belford-register-hero-section">
                <div className="container">
                    <div className="row gy-4 justify-content-center align-items-center">
                        <div className="col">
                            <div className="belford-register-box text-center">
                                <h1> <span>{t("loginData.heroSection.H1Text")}</span> </h1>
                                <h3>{t("loginData.heroSection.H3Text")}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="belford-form-section belford-register-form-section" id="belford-register-section">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-6 col-xs-12">
                            <div className="belford-form-box">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row g-4">
                                        <div className="col-12 col-md-12">
                                            <div className="form">
                                                <label htmlFor="email" className="form-label">{t("loginData.formSection.Email")}</label>
                                                <input type="email" className="form-control" id="email" placeholder="yourmail@gmail.com" aria-invalid={errors.email ? "true" : "false"} {...register("email",
                                                    {
                                                        required: "Email is required",
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            message: "Invalid email address"
                                                        }
                                                    })} />
                                            </div>
                                            {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                                        </div>
                                        <div className="col-12 col-md-12">
                                            <div className="form">
                                                <label htmlFor="create-password" className="form-label">{t("loginData.formSection.Password")}</label>
                                                <input type="password" className="form-control" id="create-password" placeholder="********************" aria-label="Create Password" aria-invalid={errors.password ? "true" : "false"} {...register("password", {
                                                    required: "Password is required",
                                                    minLength: {
                                                        value: 8,
                                                        message: "Password must be at least 8 characters long"
                                                    }
                                                })} />
                                            </div>
                                            {errors.password && <div className="invalid-feedback">{errors.password.message}</div>}
                                        </div>
                                        <div className="col-12">
                                            <div className="form text-center mb-2">
                                                <button type="submit" className="belford-btn">{t("loginData.formSection.Signin")}</button>
                                            </div>
                                            <div className="form text-center">
                                                <Link to="/register">Need an Account? Sign Up Now</Link>
                                                <p><Link to="/forgot-password">Reset your Password</Link></p>
                                            </div>
                                        </div>
                                        <div id="checkout-container-div"></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Body>
    )
}

export default Login