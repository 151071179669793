import React from 'react'
import Body from './common/Body'
import imgfounder2 from "../assets/images/img-founder-2.png"
import { useTranslation } from 'react-i18next'

const Aboutus = () => {
    const { t } = useTranslation()
    return (
        <Body>
            <section className="belford-founder-hero-section" id="belford-founder-hero-section">
                <div className="container">
                    <div className="row gy-4 justify-content-center align-items-center">
                        <div className="col-lg-6">
                            <div className="belford-founder-box">
                                <h1> <span style={{ "color": "#FFC356" }}>{t("AboutUsData.heroSection.H1Text")}</span> </h1>
                                <h3>{t("AboutUsData.heroSection.H3Text")}</h3>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="belford-founder-image">
                                <img src={imgfounder2} alt='imgfounder2' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="belford-founder-about-section" id="belford-founder-about-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                            <div className="about-content-founder text-center">
                                <h2 style={{ color: "#063358", marginBottom: "26px" }}>{t("AboutUsData.aboutSection.PText1")}</h2>
                                <p>{t("AboutUsData.aboutSection.PText2")}</p>
                                <p>{t("AboutUsData.aboutSection.PText3")}</p>
                                <p>{t("AboutUsData.aboutSection.PText4")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Body>
    )
}

export default Aboutus