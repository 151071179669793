import React from "react";
import Body from "./common/Body";
import LoadingAnimation from "../assets/images/Loading Animation.gif";
import imgplatform3 from "../assets/images/img-platform-3.png";
import imgplatform4 from "../assets/images/img-platform-4.png";
import imgplatform5 from "../assets/images/img-platform-5.png";
import imgplatform6 from "../assets/images/img-platform-6.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Platform = () => {
  const { t } = useTranslation();
  return (
    <Body>
      <section
        className="belford-platform-hero-section"
        id="belford-platform-hero-section"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="belford-hero-box">
                <h1>
                  {t("platformData.heroSection.H1Text")}
                </h1>
                <a href="https://belfordwoodsschool.com/register" className="belford-btn">
                  {t("platformData.heroSection.AText")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="belford-platform-about-section"
        id="belford-platform-about-section"
      >
        <div className="container">
          <div className="about-title text-center">
            <h2>
              {t("platformData.aboutSection.aboutTitle.H2Text.Text")}
              <span>{t("platformData.aboutSection.aboutTitle.H2Text.SpanText")}</span>
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="about-image text-center">
                <img src={LoadingAnimation} className="img-fluid" alt="about-img" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-content">
                <p>
                  {t("platformData.aboutSection.aboutContent.PText1")}
                </p>
                <p>
                  {t("platformData.aboutSection.aboutContent.PText2")}
                </p>
                <p>
                  {t("platformData.aboutSection.aboutContent.PText3")}
                </p>
                <p>
                  {t("platformData.aboutSection.aboutContent.PText4")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="belford-platform-service-section"
        className="belford-platform-service-section"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="service-header text-center">
                <h2>{t("platformData.serviceSection.serviceHeader.H2Text")}</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-12 col-xl-11 col-xxl-10">
              <div className="row justify-content-center gy-5">
                <div className="col-sm-12 col-md-5 col-lg-4">
                  <div className="service-box service-box-1">
                    <div className="service-image text-center d-flex align-items-center justify-content-center">
                      <img
                        src={imgplatform3}
                        className="img-fluid"
                        alt="service-img"
                      />
                      <h5 className="service-number">{t("platformData.serviceSection.serviceBox.Box1.H5Text")}</h5>
                    </div>
                    <h4>{t("platformData.serviceSection.serviceBox.Box1.H4Text")}</h4>
                  </div>
                </div>
                <div className="col-sm-12 col-md-5 col-lg-4">
                  <div className="service-box service-box-2">
                    <div className="service-image text-center d-flex align-items-center justify-content-center">
                      <img
                        src={imgplatform5}
                        className="img-fluid"
                        alt="about-img"
                      />
                      <h5 className="service-number">{t("platformData.serviceSection.serviceBox.Box2.H5Text")}</h5>
                    </div>
                    <h4>{t("platformData.serviceSection.serviceBox.Box2.H4Text")}</h4>
                  </div>
                </div>
                <div className="col-sm-12 col-md-5 col-lg-4">
                  <div className="service-box">
                    <div className="service-image text-center d-flex align-items-center justify-content-center">
                      <img
                        src={imgplatform6}
                        className="img-fluid"
                        alt="about-img"
                      />
                      <h5 className="service-number">{t("platformData.serviceSection.serviceBox.Box3.H5Text")}</h5>
                    </div>
                    <h4>
                      {t("platformData.serviceSection.serviceBox.Box3.H4Text")}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center service-platform-btn">
            <Link to="/contact" className="belford-btn">
              {t("platformData.serviceSection.servicePlatformBtn.LinkText")}
            </Link>
            <p>
              {t("platformData.serviceSection.servicePlatformBtn.PText")}
            </p>
          </div>
        </div>
      </section>
      <section
        className="belford-platform-subscriptions-section"
        id="belford-platform-subscriptions-section"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <div className="subscriptions-box">
                <h2>
                  {t("platformData.subscriptionsSection.subscriptionsBox.H2Text.Text")}
                  <span>{t("platformData.subscriptionsSection.subscriptionsBox.H2Text.SpanText")}</span>
                </h2>
                <p> {t("platformData.subscriptionsSection.subscriptionsBox.PText1")}</p>
                <div className="subscriptions-btn">
                  <Link to="/goldenRibbon">  {t("platformData.subscriptionsSection.subscriptionsBox.LinkText1")}</Link>
                </div>
                <p>
                  {t("platformData.subscriptionsSection.subscriptionsBox.PText2")}
                </p>
                <div className="subscriptions-btn subscriptions-btn-color">
                  <Link to="/goldenRibbon"> {t("platformData.subscriptionsSection.subscriptionsBox.LinkText2")}</Link>
                </div>
                <p>
                  {t("platformData.subscriptionsSection.subscriptionsBox.PText3")}
                </p>
                <p>
                  {t("platformData.subscriptionsSection.subscriptionsBox.PText4")}
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="subscriptions-image">
                <img src={imgplatform4} className="img-fluid" alt="about-img" />
              </div>
            </div>
          </div>
          <div className="row justify-content-center platform-subscriptions-btn">
            <Link to="/contact" className="belford-btn">
              {t("platformData.subscriptionsSection.subscriptionsBtn.LinkText")}
            </Link>
          </div>
        </div>
      </section>
      <section
        className="belford-platform-section"
        id="belford-platform-section"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="platform-header text-center">
                <h2>
                  {t("platformData.platformSection.platformHeader.H2Text.Text")}
                  <span> {t("platformData.platformSection.platformHeader.H2Text.SpanText")}</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="platform-box text-center">
                <p>
                  {t("platformData.platformSection.platformbox.PText1")}
                </p>
                <p>
                  {t("platformData.platformSection.platformbox.PText2")}
                </p>
                <p>
                  {t("platformData.platformSection.platformbox.PText3")}
                </p>
                <p>
                  {t("platformData.platformSection.platformbox.PText4")}
                </p>
                <p>
                  {t("platformData.platformSection.platformbox.PText5")}
                </p>
                <p>{t("platformData.platformSection.platformbox.PText6")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Body>
  );
};

export default Platform;
