import React, { useEffect, useState } from "react";
import img2 from "../assets/images/img-2.png";
import img3 from "../assets/images/img-3.png";
import img4 from "../assets/images/img-4.png";
import img8 from "../assets/images/img-8.png";
import { toast } from "react-toastify";
import { API } from "../helper/api";
import { useNavigate } from "react-router-dom";

const api = new API();

const Test = () => {
  const guestUserData = JSON.parse(sessionStorage.getItem("guest_user"));
  const [testDetails, setTestDetails] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [testAnswer, setTestAnswer] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getGeneralTest();
    window.scrollTo(0, 0);
    const handleScroll = () => {
      if (window.scrollY >= 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getGeneralTest = async () => {
    try {
      const response = await api.get("GENERAL_TEST");
      if (response.status === "success") {
        setTestDetails(response.data);
      }
    } catch (error) {
      console.error("Error while get General Test", error);
      toast.error(error.data?.message);
    }
  };

  const selectAnswer = (questionId, optionId) => {
    // Check if there's already an answer for this question
    const existingAnswerIndex = testAnswer.findIndex(
      (answer) => answer.question_id === questionId
    );

    // If there's an existing answer, update it
    if (existingAnswerIndex !== -1) {
      const updatedTestAnswer = [...testAnswer];
      updatedTestAnswer[existingAnswerIndex] = {
        ...updatedTestAnswer[existingAnswerIndex],
        option_id: optionId,
      };
      setTestAnswer(updatedTestAnswer);
    } else {
      // If there's no existing answer, add a new one
      setTestAnswer([
        ...testAnswer,
        {
          question_id: questionId,
          option_id: optionId,
        },
      ]);
    }
  };

  const onSubmit = async () => {
    const testData = {
      test_id: testDetails.test,
      user_id : guestUserData.id,
      questions: testAnswer,
    }
    try {
      const response = await api.post('GENERAL_TEST_STORE', testData);
      if (response.status === "success") {
        toast.success('general test has been submit successfully');
        setTimeout(() => { navigate("/result", { state: response.data }) }, 3000);
      }
    } catch (error) {
      console.error('Error while General test submit', error);
      toast.error(error);
    }
  };

  return (
    <div>
      <header
        id="header"
        className={`header belford-header result-header ${isScrolled ? "scroll" : ""
          }`}
      >
        <nav className="navbar navbar-expand-lg active-nav fixed-top">
          <div className="container">
            <div className="belford-header-menu text-center">
              <a className="navbar-brand" href="/">
                <img src={img8} className="img-fluid" alt="img8" />
              </a>
            </div>
          </div>
        </nav>
      </header>
      <section
        className="belford-test-hero-section"
        id="belford-test-hero-section"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="belford-test-box">
                {testDetails !== null && testDetails.questions.map((question, i) => {
                  return (
                    <div className="belford-test-box-info " key={question.id}>
                      <div className="belford-test-box-title">
                        <h5>{i + 1}</h5>
                        <h4>{question.question}</h4>
                      </div>
                      <ul>
                        {question.options.map((option) => {
                          return (
                            <li
                              style={{ cursor: "pointer" }}
                              key={option.id}
                              onClick={() => {
                                selectAnswer(question.id, option.id);
                              }}
                              className={
                                testAnswer.some(
                                  (answer) =>
                                    answer.option_id === option.id &&
                                    answer.question_id === question.id
                                ) ? "active" : ""
                              }
                            >
                              {option.option}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center mt-5">
            <div className="col-1">
              <button className="belford-btn" onClick={onSubmit}>
                Submit
              </button>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6">
              <div className="table-responsive belford-test-teble-section">
                <table className="table">
                  <thead>
                    <tr>
                      <th colSpan="2" className="text-center">
                        Result Chart
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="test-teble-title">Score Level</td>
                      <td className="test-teble-title">Level</td>
                    </tr>
                    <tr>
                      <td>from 0 to 4 correct answers</td>
                      <td>A1</td>
                    </tr>
                    <tr>
                      <td>from 5 to 11 correct answers</td>
                      <td>A2</td>
                    </tr>
                    <tr>
                      <td>from 12 to 16 correct answers</td>
                      <td>B1</td>
                    </tr>
                    <tr>
                      <td>B1+ from 17 to 18 correct answers</td>
                      <td>B1+</td>
                    </tr>
                    <tr>
                      <td>B2 from 19 and above</td>
                      <td>B2</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="result-footer">
        <div className="belford-footer-bottom">
          F
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-3">
                <div className="belford-footer-bottom-img">
                  <div className="belford-footer-bottom-img">
                    <a href="/">
                      <img src={img8} className="img-fluid" alt="img8" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-3">
                <div className="belford-footer-bottom-social-icon">
                  <ul>
                    <li>
                      <a href="/">
                        <img src={img2} className="img-fluid" alt="img2" />
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <img src={img3} className="img-fluid" alt="img3" />
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <img src={img4} className="img-fluid" alt="img4" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="belford-footer-bottom-content justify-content-lg-end">
                  <ul>
                    <li>
                      <a href="/">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="/">Terms and Conditions</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="belford-footer-copy-right">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="belford-footer-site-info text-center">
                  <p>
                    © <a href="/">Belford Woods School</a> Registered Trademark
                    2023 P.I.17343501007{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Test;
