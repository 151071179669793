import React from 'react'
import Body from './common/Body'
import imgservice2 from "../assets/images/Certficates.png"
import { useTranslation } from 'react-i18next'
import certificateImg from "../assets/images/Certificates-hero.png"
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { API } from '../helper/api'

const countries = [
    "Abu Dhabi",
    "Aden",
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Antarctica",
    "Antigua",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Antarctica Territory",
    "British Indian Ocean Territory",
    "British Virgin Islands",
    "British West Indies",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Canal Zone",
    "Canary Island",
    "Cape Verdi Islands",
    "Cayman Islands",
    "Chad",
    "Channel Island Uk",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Island",
    "Colombia",
    "Comoro Islands",
    "Congo",
    "Congo Kinshasa",
    "Cook Islands",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Curacao",
    "Cyprus",
    "Czech Republic",
    "Dahomey",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Dubai",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Estonia",
    "Ethiopia",
    "Faeroe Islands",
    "Falkland Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guinea",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "Ifni",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Ivory Coast",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Korea",
    "Korea, South",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Leeward Islands",
    "Lesotho",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Melanesia",
    "Mexico",
    "Moldovia",
    "Monaco",
    "Mongolia",
    "Morocco",
    "Mozambique",
    "Myanamar",
    "Namibia",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "Netherlands Antilles Neutral Zone",
    "New Caladonia",
    "New Hebrides",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Norfolk Island",
    "Norway",
    "Oman",
    "Other",
    "Pacific Island",
    "Pakistan",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Portuguese Timor",
    "Puerto Rico",
    "Qatar",
    "Republic Of Belarus",
    "Republic Of South Africa",
    "Reunion",
    "Romania",
    "Russia",
    "Rwanda",
    "Ryukyu Islands",
    "Sabah",
    "San Marino",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Somaliliand",
    "South Africa",
    "South Yemen",
    "Spain",
    "Spanish Sahara",
    "Sri Lanka",
    "St. Kitts And Nevis",
    "St. Lucia",
    "Sudan",
    "Surinam",
    "Sw Africa",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Tonga",
    "Trinidad",
    "Tunisia",
    "Turkey",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "Uruguay"
]

const api = new API();

const Certificate = () => {
    const { t, i18n } = useTranslation();
    const { handleSubmit, reset, register, formState: { errors } } = useForm();

    const onSubmit = async (values) => {
        try {
            delete values['check'];
            values['language'] = i18n.language;
            values['referral_source'] = "null"
            const response = await api.post('CONTACT_US', values);
            if (response.status === true) {
                reset()
                toast.success('Contact has been done successfully.');
            }
        } catch (error) {
            console.error('Error while changing Contact', error);
            toast.error(error);
        }
    }
    return (
        <Body>
            <section className="belford-certificate-hero-section" id="belford-certificate-hero-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-6 d-flex align-items-center">
                            <div className="belford-certificate-hero-content">
                                <h1>{t("certificateData.heroSection.H1Text")}</h1>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6  ">
                            <img src={certificateImg} />
                        </div>
                    </div>
                </div>
            </section>
            <section className="belford-certificate-about-section" id="belford-certificate-about-section">
                <div className="container">
                    <div className="row gy-4 gy-lg-0 align-items-center">
                        <div className="col-12 col-lg-6">
                            <div className="belford-certificate-about-img">
                                <img src={imgservice2} alt='imgservice2' width="100%" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="belford-certificate-about-content">
                                <p>{t("certificateData.aboutSection.PText1.Text")}<strong>{t("certificateData.aboutSection.PText1.strongText1")}</strong>{t("certificateData.aboutSection.PText1.textAfterStrong")}</p>
                                <p>{t("certificateData.aboutSection.PText2")}</p>
                                <p>{t("certificateData.aboutSection.PText3")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="belford-form-section" id="belford-form-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <div className="belford-form-box">
                                <div className="belford-form-heading text-center">
                                    <h2><span>{t("certificateData.formSection.formHeading")}</span></h2>
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row g-4">
                                        <div className="col-12 col-md-6">
                                            <div className="form">
                                                <label htmlFor="first_name" className="form-label">{t("ContactData.formSection.firstName")}</label>
                                                <input type="text" className="form-control" id="first_name" placeholder="Name" aria-label="First name" aria-invalid={errors.firstname ? "true" : "false"} {...register("firstname", {
                                                    required: "Firstname is required", pattern: {
                                                        value: /^[A-Za-z]+$/,
                                                        message: "Only alphabets are allowed"
                                                    }
                                                })} />
                                            </div>
                                            {errors.firstname && <div className="invalid-feedback">{errors.firstname.message}</div>}
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form">
                                                <label htmlFor="last_name" className="form-label">{t("ContactData.formSection.lastName")}</label>
                                                <input type="text" className="form-control" id="last_name" placeholder="Last Name" aria-label="Last name" aria-invalid={errors.lastname ? "true" : "false"} {...register("lastname", {
                                                    required: "Lastname is required", pattern: {
                                                        value: /^[A-Za-z]+$/,
                                                        message: "Only alphabets are allowed"
                                                    }
                                                })} />
                                            </div>
                                            {errors.lastname && <div className="invalid-feedback">{errors.lastname.message}</div>}
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form">
                                                <label htmlFor="email" className="form-label">{t("ContactData.formSection.Email")}</label>
                                                <input type="email" className="form-control" id="email" placeholder="yourmail@gmail.com" aria-label="Email" aria-invalid={errors.email ? "true" : "false"} {...register("email",
                                                    {
                                                        required: "Email is required",
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            message: "Invalid email address"
                                                        }
                                                    })} />
                                            </div>
                                            {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form">
                                                <label htmlFor="country" className="form-label">{t("ContactData.formSection.Country")}</label>
                                                <select id="country" className="form-select" {...register("country", { required: "Country is required" })}>
                                                    <option value="">Select Country</option>
                                                    {countries.map((country) => {
                                                        return <option value={country} key={country}>{country}</option>
                                                    })}
                                                </select>
                                            </div>
                                            {errors.country && <div className="invalid-feedback">{errors.country.message}</div>}
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form">
                                                <label htmlFor="phone" className="form-label">{t("ContactData.formSection.Phone")}</label>
                                                <input type="phone" className="form-control" id="phone" placeholder="+1 (555) 000-0000" aria-invalid={errors.phone ? "true" : "false"} {...register("phone", { required: "Phone is required" })} />
                                            </div>
                                            {errors.phone && <div className="invalid-feedback">{errors.phone.message}</div>}
                                        </div>
                                        <div className="col-12">
                                            <div className="form">
                                                <label htmlFor="Message" className="form-label">{t("ContactData.formSection.Message")}</label>
                                                <textarea className="form-control" id="Message" rows="8" placeholder="Your Message" {...register("message", { required: "It is required" })}></textarea>
                                            </div>
                                            {errors.message && <div className="invalid-feedback">{errors.message.message}</div>}
                                        </div>
                                        <div className="col-12">
                                            <div className="form-check">
                                                <input className="form-input" type="checkbox" id="check" {...register("check", { required: "It is required" })} />
                                                <label className="form-label" htmlFor="check">{t("ContactData.formSection.checkbox.Text")}<a href="/">{t("ContactData.formSection.checkbox.AnchorText")}</a> {t("ContactData.formSection.checkbox.textAfterAnchor")}</label>
                                            </div>
                                            {errors.check && <div className="invalid-feedback">{errors.check.message}</div>}
                                        </div>
                                        <div className="col-12">
                                            <div className="form text-center">
                                                <button type="submit" className="belford-btn">{t("ContactData.formSection.belfordBtn.Text")}</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Body>
    )
}

export default Certificate