import React, { useCallback, useEffect, useState, Suspense, lazy } from "react";
import Body from "./common/Body";
// import imgtranslation2 from "../assets/images/img-translation-2.png";
// import PrivateMenu from "./common/PrivateMenu";
import { getCurrentUser } from "../helper/Authorization";
import { API } from "../helper/api";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const PrivateMenu = lazy(() => import( "./common/PrivateMenu"));
const api = new API();
const Dashboard = () => {
  const [userProgess, setUserProgess] = useState(0);
  const [userLevel, setUserLevel] = useState(null);
  const [getCourse, setCourse] = useState(null);

  useEffect(() => {
    getProgress();
    const currentUser = getCurrentUser();
    if (currentUser.student) {
      setCourse(currentUser.student.course.id);
      setUserLevel(currentUser.student.level.name);
    }
  }, []);

  const getProgress = async () => {
    try {
      const response = await api.get("STUDENT_PROGRESS");
      if (response.status === true) {
        setUserProgess(response.data.progress);
      }
    } catch (error) {
      console.error("Error while get Progess Test", error);
      toast.error(error.data.message);
    }
  };

  const upgradeSubscription = async (event) => {
    event.preventDefault();
    try {
      const response = await api.get("Upgrade_Subscription");
      if (response.status === true) {
        window.location.href = response.data.url;
      }
    } catch (error) {
      console.error("Error while get Progess Test", error);
      toast.error(error.data.message);
    }
  };
  return (
    <Body>
      <section
        className="belford-withdraw-hero-section belford-withdraw-golden belford-withdraw-blue"
        id="belford-withdraw-hero-section"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8">
              <div className="belford-withdraw-hero-content text-center">
              <Suspense fallback={<div>Loading...</div>}>
                <PrivateMenu />
              </Suspense>
                <h1>
                  <span>Welcome to The Platform</span>
                </h1>
                { getCourse === 2 &&
                <div className="subscriptions-btn">
                  <a href="/" onClick={upgradeSubscription}>Upgrade to Golden Ribbon</a>
                </div>
                }
                <p>
                  Your Level <span>{userLevel}</span>
                </p>
                <div className="progress mb-3">
                  <div className="progress-bar" role="progressbar" style={{ width: `${userProgess}%` }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" ></div>
                </div>
                <p>
                  You have completed {userProgess}% of your English course.
                </p>
                <Link to="/units" className="belford-btn">
                  Go to Units
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="belford-journey-section" id="belford-journey-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-7">
              <div className="belford-journey-heading text-center">
                <h2>
                  <span>Your English</span> Journey
                </h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-7">
              <div className="belford-journey-table table-responsive">
                <table>
                  <tbody>
                    <tr>
                      <td>Unit 1</td>
                    { userLevel !== 'A1' && <td>Unit 11</td>}
                    </tr>
                    <tr>
                      <td>Unit 2</td>
                      { userLevel !== 'A1' && <td>Unit 12</td> }
                    </tr>
                    <tr>
                      <td>Unit 3</td>
                      { userLevel !== 'A1' &&  <td>Unit 13</td> }
                    </tr>
                    <tr>
                      <td>Unit 4</td>
                      { userLevel !== 'A1' && <td>Unit 14</td> }
                    </tr>
                    <tr>
                      <td>Unit 5</td>
                      { userLevel !== 'A1' &&  <td>Unit 15</td> }
                    </tr>
                    <tr>
                      <td>Unit 6</td>
                      { userLevel !== 'A1' &&  <td>Unit 16</td> }
                    </tr>
                    <tr>
                      <td>Unit 7</td>
                      { userLevel !== 'A1' &&  <td>Unit 17</td> }
                    </tr>
                    <tr>
                      <td>Unit 8</td>
                      { userLevel !== 'A1' &&  <td>Unit 18</td> }
                    </tr>
                    <tr>
                      <td>Unit 9</td>
                      { userLevel !== 'A1' &&  <td>Unit 19</td> }
                    </tr>
                    <tr>
                      <td>Unit 10</td>
                      { userLevel !== 'A1' &&  <td>Unit 20</td> }
                    </tr>
                    <tr>
                      { userLevel !== 'A1' && <th scope="col">Mid-level Test</th> }
                      <th scope="col">Final test</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-7">
              <div className="belford-journey-btn">
                <Link to="/units" className="belford-btn">
                  Go to Your Units
                </Link>
                <Link to="/profile"  className="belford-btn belford-btn-primary">
                  Terminate Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Body>
  );
};

export default Dashboard;
